import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";

import { getWatchList, setWatchList } from "../actions";
import firebase, { db } from "../base";

const mapStateToProps = state => {
  return {
    watchlist: state.watchlist,
    loading: state.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getWatchList: payload => dispatch(getWatchList(payload)),
    setWatchList: payload => dispatch(setWatchList(payload))
  };
};

class Eye extends React.Component {
  componentDidMount() {
    const { currentUser } = firebase.auth();

    if (currentUser) {
      const { uid } = firebase.auth().currentUser;
      this.props.getWatchList(uid);
    }
  }

  updateWatchList = (imdbID, title, poster, type, action) => {
    if (firebase.auth().currentUser) {
      const { uid } = firebase.auth().currentUser;
      const newWatchList = new Set(Array.from(this.props.watchlist));

      let sentence1 = "";
      let sentence2 = "";
      if (action === "delete") {
        newWatchList.delete(imdbID);
        sentence1 = "Removed from your WatchList";
        sentence2 = `You've removed "${title}" from your WatchList.`;
      } else if (action === "add") {
        newWatchList.add(imdbID);
        sentence1 = "Added to your WatchList";
        sentence2 = `You've added "${title}" to your WatchList.`;
      }

      
      db.collection("watchlist")
        .doc(uid)
        .set({ imdb: Array.from(newWatchList) })
        .then(() => {
          this.props.setWatchList(newWatchList);
          Swal.fire(sentence1, sentence2, "success");
          if (this.props.callback) {
            this.props.callback();
          }
        });

      // add to all movies
     db.collection("all_shows")
        .doc(imdbID)
        .set({ title, imdbID, poster, type });
    } else {
      Swal.fire({
        title: "Sign Up and Create a Watch List",
        text:
          "Sign Up for Free to start adding to your Watch List and share reviews with friends.",
        icon: "success",
        cancelButtonText: "later",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Let's Do It!"
      }).then(result => {
        if (result.value) {
          this.props.history.push("/signup");
        }
      });
    }
  };

  render() {
    const inWatchList = this.props.watchlist
      ? this.props.watchlist.has(this.props.imdbID)
      : false;

    return (
      <>
        {!this.props.loading && (
          <span
            className="pointer eye"
            id={`eye${this.props.i}`}
            onClick={() =>
              inWatchList
                ? this.updateWatchList(
                    this.props.imdbID,
                    this.props.title,
                    this.props.poster,
                    this.props.type,
                    "delete"
                  )
                : this.updateWatchList(
                    this.props.imdbID,
                    this.props.title,
                    this.props.poster,
                    this.props.type,
                    "add"
                  )
            }
          >
            <i
              className="fad fa-eye"
              style={{
                "--fa-primary-color": "lightblue",
                "--fa-secondary-color": inWatchList ? "gold" : "lightgray",
                "--fa-secondary-opacity": "1.0"
              }}
            ></i>
            {!isMobile && (
              <UncontrolledTooltip
                placement="top"
                target={`eye${this.props.i}`}
              >
                {inWatchList ? "Remove from " : "Add to "}your watchlist
              </UncontrolledTooltip>
            )}
          </span>
        )}
      </>
    );
  }
}

const EyeComponent = connect(mapStateToProps, mapDispatchToProps)(Eye);
export default withRouter(EyeComponent);
