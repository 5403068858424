import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";
import qs from "qs";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput
} from "mdbreact";
import PulseLoader from "react-spinners/PulseLoader";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import firebase, { analytics } from "./base";
import { SUPPORT_EMAIL, PROD_HP_URL } from "./utils/variables.js";
import { processGroup, processSocialAuth } from "./utils/auth.js";
import logo from "./assets/img/TGF-logo-white.png";

const SignUp = ({ history }) => {
  const [loading, setLoading] = useState(false);

  // Configure FirebaseUI.
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        setLoading(true);
        processSocialAuth(authResult, redirectUrl, history);

        return false;
      }
    }
    // tosUrl: 'https://thegoodflix.com/terms-of-use/',
    // privacyPolicyUrl: 'https://thegoodflix.com/privacy-policy/'
  };

  const handleSignUp = async event => {
    event.preventDefault();

    setLoading(true);

    const { group } = qs.parse(window.location.search.slice(1));

    const { email, password, displayName } = event.target.elements;
    const data = {
      checked: group ? false : true,
      group,
      displayName: displayName.value,
      email: email.value,
      password: password.value
    };

    processGroup(data).then(result => {
      if (result) {
        analytics.logEvent("sign_up", { method: 'email', email: email.value });
        history.push("/");
      } else {
        setLoading(false);
      }
    });
  };

  window.scroll(0, 0);

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6" className="ml-auto mr-auto">
          <MDBCard>
            <div className="header pt-3 red">
              <MDBRow className="d-flex justify-content-start">
                <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                  <a href={PROD_HP_URL}>
                    <img
                      src={logo}
                      alt="TheGoodflix Logo"
                      className="logo-login"
                    />
                  </a>
                </h3>
              </MDBRow>
            </div>
            <br />
            <h3 className="grey-text mt-3 mb-4 pb-1 mx-5">
              <span className="center">Sign up with</span>
            </h3>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
            <Form onSubmit={handleSignUp}>
              <br />
              <h3 className="grey-text mt-3 mb-4 pb-1 mx-5">
                <span className="center">or</span>
              </h3>
              <MDBCardBody className="mx-4 mt-4">
                <MDBInput
                  label="Your nickname..."
                  name="displayName"
                  group
                  type="text"
                  validate
                  containerClass="mb-0"
                  required
                />
                <MDBInput
                  label="Your email..."
                  name="email"
                  group
                  type="email"
                  autoComplete="username"
                  validate
                  required
                />
                <MDBInput
                  label="Your password..."
                  group
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  validate
                  containerClass="mb-0"
                  required
                />
                <div className="text-center mb-4 mt-5">
                  {loading ? (
                    <PulseLoader
                      //css={override}
                      sizeUnit={"px"}
                      size={26}
                      color={"red"}
                      loading={loading}
                    />
                  ) : (
                    <MDBBtn
                      type="submit"
                      className="btn-block z-depth-2"
                      color="info"
                    >
                      Sign Up
                    </MDBBtn>
                  )}
                </div>
                <p className="font-small grey-text d-flex justify-content-center">
                  Already have an account?
                  <Link
                    to="/login"
                    className="dark-grey-text font-weight-bold ml-1"
                  >
                    Log In
                  </Link>
                </p>
                <p className="font-small grey-text d-flex justify-content-center">
                  Need a help with a new friend group?
                  <a
                    href={`mailto:${SUPPORT_EMAIL}?subject=New%20Group%20Request&body=Please%20create%20a%20new%20group%20for%20my%20friends%20called...[YOUR%20GROUP%20NAME]`}
                    className="dark-grey-text font-weight-bold ml-1"
                  >
                    Request
                  </a>
                </p>
              </MDBCardBody>
            </Form>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default withRouter(SignUp);
