import React from "react";
import PropTypes from "prop-types";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import qs from "qs";
import { Form, Row, Col } from "reactstrap";
import { MDBInput, MDBBtn, MDBCard, MDBBtnGroup, MDBIcon } from "mdbreact";
import { isMobile } from "react-device-detect";

import { getMovieList } from "../actions";
import firebase, { analytics } from "../base";

const mapDispatchToProps = dispatch => {
  return {
    getMovieList: payload => dispatch(getMovieList(payload))
  };
};

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: ""
    };
  }

  componentDidMount() {
    this.setState({
      displayName: firebase.auth().currentUser.displayName
    });

    setTimeout(() => {
      this.setState({
        displayName: firebase.auth().currentUser.displayName
      });
    }, 500);
  }

  search = e => {
    e.preventDefault();

    const { q } = e.target.elements;
    const value = q.value.trim();
    const search = this.props.location.search.slice(1);
    const parsed = qs.parse(search);

    analytics.logEvent("search", { search_term: value });

    if (value.length !== 0 && parsed.q !== value) {
      this.props.history.push(
        `/movies?q=${value}${
          this.props.source === "home" ? "&source=hp" : ""
        }`
      );
      if (this.props.caller === "list-page") {
        this.props.getMovieList({ q: value, page: 1 });
      }
    } else {
      console.error("No data");
    }
  };

  render() {
    return (
      <MDBCard style={{ width: isMobile ? "22rem" : "32rem", height: "auto" }}>
        <br />
        <div className="row search-form search-margin">
          <div
            className={`${
              this.props.column ? this.props.column : "col-md-12"
            } ${this.props.colClass}`}
          >
            <Form onSubmit={this.search}>
              <h2 className="page-title">Search for a Show</h2>
              <MDBInput
                type="text"
                label="Enter title..."
                id="search"
                name="q"
              />
              <MDBBtn
                className="btn btn-primary btn-md font-1"
                type="submit"
                color="amber"
              >
                Search
              </MDBBtn>{" "}
              <br />
              <br />
              <br />
              <Row className="search-bottom-btns">
                <div className="btn-toolbar" role="toolbar">
                  <MDBBtnGroup
                    size={isMobile ? "" : "lg"}
                    className={isMobile ? "search-wrap" : ""}
                  >
                    <Col>
                      <Link to="/uml">
                        <MDBBtn color="cyan lighten-2" outline>
                          <MDBIcon
                            fal
                            icon="camera-movie"
                            className="search-icon"
                          />
                          <br />
                          My Shows
                        </MDBBtn>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/gml">
                        <MDBBtn color="indigo lighten-2" outline>
                          <MDBIcon fal icon="magic" className="search-icon" />
                          <br />
                          Watch Next
                        </MDBBtn>
                      </Link>
                    </Col>
                    <Col>
                      <Link to="/watchlist">
                        <MDBBtn color="cyan lighten-2" outline>
                          <MDBIcon fal icon="eye" className="search-icon" />
                          <br />
                          Watch List
                        </MDBBtn>
                      </Link>
                    </Col>
                  </MDBBtnGroup>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </MDBCard>
    );
  }
}

SearchForm.propTypes = {
  column: PropTypes.string
};

const SearchFormComponent = connect(
  null,
  mapDispatchToProps
)(SearchForm);
export default withRouter(SearchFormComponent);
