import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput
} from "mdbreact";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import firebase, { db } from "../base";
import { getMovieDetail, getUser } from "../actions";
import { mapService, shortenTwitter } from "../utils/funcs.js";
import {
  SERVICES,
  PROD_APP_URL,
  SUPPORT_EMAIL,
  isProd
} from "../utils/variables.js";

const mapStateToProps = state => {
  return {
    item: state.item,
    user: state.user,
    friends: state.friends
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMovieDetail: payload => dispatch(getMovieDetail(payload)),
    getUser: payload => dispatch(getUser(payload))
  };
};

class SubmitReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareDisabled: true,
      dropDownValue: "Where did you watch?",
    };
  }

  getPlot = () => {
    this.props.getMovieDetail(this.props.data.imdbID).then(() => {
      this.setState({
        plot: this.props.item.Plot !== "N/A" ? this.props.item.Plot : ""
      });
    });
  };

  toggle = () => {
    this.setState({
      shareDisabled: true,
      dropDownValue: "Where did you watch?"
    });

    this.props.handleClose();
  };

  image() {
    if (!this.props.data.Poster || this.props.data.Poster !== "N/A") {
      return <img src={this.props.data.Poster} className="img-fluid" alt="" />;
    } else {
      return;
    }
  }

  doExist = async (review, imdbID) => {
    const currentUser = firebase.auth().currentUser;

    await db
      .collection("userMovieList")
      .where("userId", "==", currentUser.uid)
      .where("imdbID", "==", imdbID)
      .where("group", "==", this.props.user.groups[0])
      .get()
      .then(async querySnapshot => {
        if (!querySnapshot.empty) {
          Swal.fire("Yo", "You already added this to your list!", "warning");
        } else {
          const movieData = {
            userId: currentUser.uid,
            imdbID: this.props.data.imdbID,
            title: this.props.data.Title,
            year: this.props.data.Year,
            type: this.props.data.Type,
            review,
            rating: this.state.rating,
            poster: this.props.data.Poster,
            plot: this.props.data.Plot,
            displayName: currentUser.displayName,
            group: this.props.user.groups[0],
            service: this.state.service[0],
            created: firebase.firestore.Timestamp.fromDate(new Date())
          };

          if(this.props.data.Episode) {
            movieData.episode = this.props.data.Episode;
            movieData.season = this.props.data.Season;
            movieData.seriesTitle = this.props.data.seriesTitle;
          }

          await db.collection("userMovieList").add(movieData);

          // Add Review
          await db
            .collection("reviews")
            .doc(this.props.data.imdbID)
            .collection("reviews")
            .doc()
            .set({
              imdbID: this.props.data.imdbID,
              userId: currentUser.uid,
              review,
              rating: this.state.rating,
              displayName: currentUser.displayName,
              group: this.props.user.groups[0],
              service: this.state.service[0],
              created: firebase.firestore.Timestamp.fromDate(new Date())
            });

          this.showSuccess(movieData);
          this.sendEmail(movieData, "new_show");

          if (this.props.callback) {
            this.props.callback();
          }
        }
      })
      .catch(error => {
        console.error("Error getting documents: ", error);
      });
  };

  showSuccess = movieData => {
    const reviewPro = shortenTwitter(movieData.title, movieData.review);
    const link = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      reviewPro
    )}%20-%20I%20just%20reviewed%20${encodeURIComponent(
      `#${movieData.title.replace(/\s/g, "").replace(/'/g, '')}`
    )}%20on%20@thegoodflix%20&url=${process.env.REACT_APP_DOMAIN}/app/show?id=${
      movieData.imdbID
    }`;

    let emailDirty = false;
    const sweetData = {
      icon: "success",
      title: "Congrats!",
      text: `You've just accomplished something today by reviewing ${movieData.title}.`,
      footer: `<a href="${link}" target="popup" onclick="window.open('${link}', 'tweet','width=600,height=400')">Share on <i class="fab fa-twitter twitter-color"></i></a>`
    };

    if (this.props.data.friends < 2) {
      sweetData.confirmButtonText = "Invite Friend";
      sweetData.input = "email";
      sweetData.inputAttributes = {
        autocapitalize: "off"
      };
      sweetData.inputPlaceholder = "Friend's email...";
      sweetData.text += " Now share it with a new friend!";
      sweetData.cancelButtonText = "later";
      sweetData.showCancelButton = true;
      sweetData.showLoaderOnConfirm = true;
      sweetData.reverseButtons = true;
      sweetData.preConfirm = email => {
        emailDirty = true;
        this.sendEmail(movieData, "invite_friend", email);
      };
      sweetData.allowOutsideClick = () => !Swal.isLoading();
    }

    Swal.fire(sweetData).then(() => {
      if (emailDirty) {
        Swal.fire({
          text: "Your friends are about to have the best day ever.",
          imageUrl: movieData.poster
        });
      }
    });
  };

  sendEmail = (data, template, email) => {
    // const { currentUser } = firebase.auth();
    const newData = { ...data };

    if (newData.rating === "up") {
      newData.rating = "👍";
    } else {
      newData.rating = "👎";
    }

    newData.service = `${process.env.REACT_APP_DOMAIN}/services/${mapService(
      newData.service
    )}.png`;

    newData.title = this.escapeTitle(newData.seriesTitle && !newData.title.includes(':') ? `${newData.seriesTitle}: ${newData.title}` : newData.title);
    newData.domain = process.env.REACT_APP_DOMAIN;
    newData.invite_url = `${PROD_APP_URL}/signup?group=${encodeURIComponent(
      this.props.user.groups[0]
    )}`;
    newData.friend_url = `mailto:?subject=Join me on theGoodflix&body=We can start sharing our shows on theGoodflix.%0D%0A%0D%0AHere is the signup: ${PROD_APP_URL}/signup?group=${encodeURIComponent(
      encodeURIComponent(this.props.user.groups[0])
    )}`;

    const mailCollection = db.collection("mail");

    const emailData = {
      template: {
        name: template,
        data: newData
      }
    };

    if (isProd) {
      // in prod BCC
      console.log("Is Prod");
      emailData.bcc = SUPPORT_EMAIL;
    }

    if (email) {
      emailData.to = email;
      mailCollection.add(emailData);
    } else {
      db.collection("users")
        .where("groups", "array-contains", this.props.user.groups[0])
        .get()
        .then(querySnapShot => {
          if (!querySnapShot.empty) {
            const uids = querySnapShot.docs
              .map(doc => doc.id);
              // .filter(x => x !== currentUser.uid);

            emailData.ccUids = uids;
            mailCollection.add(emailData);
          } else {
            console.error("Groups not found");
          }
        });
    }
  };

  escapeTitle = title => {
    return (
      title
        .replace(/&/g, "and")
        /*.replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')*/
        .replace(/'/g, "")
    );
  };

  saveToUserList = async formData => {
    try {
      this.toggle();
      await this.doExist(formData.review, this.props.data.imdbID);
    } catch (err) {
      console.error(err);
    }
  };

  setRating = val => {
    this.setState({
      rating: val,
      shareDisabled: !!!this.state.service
    });
  };

  setService = (e, service) => {
    this.setState({
      service,
      dropDownValue: e.currentTarget.textContent,
      shareDisabled: !!!this.state.rating
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const formData = Array.from(e.target.elements)
      .filter(el => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {});

    this.saveToUserList(formData).then(() => {
      if (this.props.refresh) {
        this.props.refresh();
      }
    });
  };

  render() {
    return (
      <MDBContainer>
        <MDBModal isOpen={this.props.show} fade={false} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>
            <b>Share {this.props.data.Title} with your group</b>
          </MDBModalHeader>
          <Form onSubmit={e => this.handleSubmit(e)}>
            <MDBModalBody>
              <br />
              <h4>Do you like {this.props.data.Title}?</h4>
              <br />
              <Row>
                <Col>
                  <MDBBtn
                    outline
                    onClick={() => this.setRating("up")}
                    color={
                      this.state.rating === "up"
                        ? "cyan accent-1"
                        : "mdb-color lighten-1"
                    }
                    className={`font-2em pointer margin-thumbs ${
                      this.state.rating === "down" ? "opacity-button" : ""
                    }`}
                  >
                    <i className="fal fa-thumbs-up"></i>
                  </MDBBtn>
                  <MDBBtn
                    outline
                    onClick={() => this.setRating("down")}
                    color={
                      this.state.rating === "down"
                        ? "cyan accent-1"
                        : "mdb-color lighten-1"
                    }
                    className={`font-2em pointer ${
                      this.state.rating === "up" ? "opacity-button" : ""
                    }`}
                  >
                    <i className="fal fa-thumbs-down"></i>
                  </MDBBtn>
                </Col>
              </Row>
              <br />
              <UncontrolledDropdown required>
                <DropdownToggle color="cyan-light" caret>
                  {this.state.dropDownValue}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Select Service</DropdownItem>
                  {Array.from(SERVICES)
                    .sort((a, b) => {
                      const aVal = a[1].toLowerCase();
                      const bVal = b[1].toLowerCase();
                      return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
                    })
                    .map((service, i) => (
                      <DropdownItem
                        key={i}
                        onClick={e => this.setService(e, service)}
                      >
                        {service[1]}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <br />
              <Row className="review-text">
                <MDBInput
                  type="textarea"
                  name="review"
                  label="Your review..."
                  outline
                  required
                />
              </Row>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color=" grey lighten-1" onClick={this.toggle}>
                <span className="white-color">Cancel</span>
              </MDBBtn>
              <MDBBtn
                color="primary"
                type="submit"
                disabled={this.state.shareDisabled}
              >
                Share
              </MDBBtn>
            </MDBModalFooter>
          </Form>
        </MDBModal>
      </MDBContainer>
    );
  }
}

SubmitReview.propTypes = {
  ref: PropTypes.object,
  data: PropTypes.object,
  refresh: PropTypes.func
};

const SubmitReviewComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitReview);
export default withRouter(SubmitReviewComponent);
