import React from "react";

import { withRouter, Link } from "react-router-dom";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBBtnGroup
} from "mdbreact";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import PulseLoader from "react-spinners/PulseLoader";

import SubmitReviewComponent from "./SubmitReviewComponent.jsx";
import HeaderComponent from "./HeaderComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";
import EyeComponent from "./EyeComponent.jsx";
import ReviewsComponent from "./ReviewsComponent.jsx";
import firebase, { db } from "../base";
import { getMovieDetail, getUser, getWatchList, getFriends } from "../actions";
import { SERVICES } from "../utils/variables.js";
import { formatService, mapService } from "../utils/funcs.js";
// import { GROUP_LIST } from "../constants";

const serviceImages = require.context("../assets/img/services", true);

const mapStateToProps = state => {
  return {
    item: state.item,
    user: state.user,
    value: "All Shows",
    watchlist: state.watchlist,
    friends: state.friends,
    toggleGiveReview: false
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMovieDetail: payload => dispatch(getMovieDetail(payload)),
    getUser: payload => dispatch(getUser(payload)),
    getWatchList: payload => dispatch(getWatchList(payload)),
    getFriends: payload => dispatch(getFriends(payload))
  };
};

const sortShows = (a, b, year) => {
  let yearA = a.year.trim();
  let yearB = b.year.trim();
  yearA = yearA.endsWith("–")
    ? year
    : yearA.includes("–")
    ? yearA.slice(yearA.indexOf("–") + 1, yearA.length)
    : yearA;
  yearB = yearB.endsWith("–")
    ? year
    : yearB.includes("–")
    ? yearB.slice(yearB.indexOf("–") + 1, yearB.length)
    : yearB;

  return parseInt(yearB) - parseInt(yearA);
};

const sortReviewed = (a, b) => {
  // Find largest
  const aLargest = Math.max(...a.created.map(x => x.seconds));
  const bLargest = Math.max(...b.created.map(x => x.seconds));

  return bLargest - aLargest;
};

const reducer = (accumulator, currentValue) => accumulator + currentValue;
const convertRating = a => {
  return a.ratings
    .map(x => {
      if (x === "up") {
        return 1;
      } else if (x === "down") {
        return -1;
      } else {
        return 0;
      }
    })
    .reduce(reducer);
};
const sortRated = (a, b) => {
  const aRated = convertRating(a);
  const bRated = convertRating(b);

  return bRated - aRated;
};

class UserMovieList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showData: {},
      flipped: false,
      users: [],
      reviews: [],
      ratings: [],
      filterMyMovies: true,
      trashTooltipOpen: false,
      checkedSwitch: true,
      loading: true,
      sortType: "released"
    };
  }

  componentDidMount() {
    const { uid } = firebase.auth().currentUser;
    this.props.getUser(uid).then(() => {
      this.props.getFriends(this.props.user ? this.props.user.groups[0] : "");
      this.refresh();
    });

    this.props.getWatchList(uid);
  }

  diff = (arr1, arr2) => {
    return arr1.filter(x => !arr2.includes(x));
  };

  getMovieList = () => {
    let dbUserList = db.collection("userMovieList");
    let dbProcess;

    const uid = firebase.auth().currentUser.uid;
    if (!this.props.groupID && !this.props.isWatchList) {
      dbProcess = dbUserList.where("userId", "==", uid);
    } else {
      dbProcess = dbUserList;
    }

    dbProcess
      .where("group", "==", this.props.user ? this.props.user.groups[0] : "")
      .get()
      .then(querySnapshot => {
        const dataDB = querySnapshot.docs.map(doc => doc.data());
        const unique = [...new Set(dataDB.map(item => item.imdbID))];

        const userMovies = [];
        unique.forEach(id => {
          const find = dataDB.find(x => id === x.imdbID && uid === x.userId);
          if (find) userMovies.push(find);
        });
        const imdbIDs = userMovies.map(u => u.imdbID);
        const diffIDs = this.diff(unique, imdbIDs);
        const otherMovies = diffIDs.map(id =>
          dataDB.find(x => id === x.imdbID && !imdbIDs.includes(x.imdbID))
        );

        const data = userMovies
          ? this.props.isWatchList
            ? userMovies.concat(otherMovies)
            : !this.props.groupID
            ? userMovies
            : this.state.filterMyMovies
            ? otherMovies
            : userMovies.concat(otherMovies)
          : [];

        this.setState(
          {
            movieLength: data.length,
            userMovies,
            otherMovies,
            movieListComplete: true,
            movieData: data
          },
          () => {
            this.getCards(data).then(() => this.setState({ loading: false }));
          }
        );
      })
      .catch(error => {
        console.error("Error getting documents: ", error);
      });
  };

  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  };

  getGroupedMovies = async () => {
    const reviewsDB = db
      .collectionGroup("reviews")
      .where("group", "==", this.props.user ? this.props.user.groups[0] : "");

    await reviewsDB.get().then(snapShot => {
      if (!snapShot.empty) {
        const data = snapShot.docs.map(doc => doc.data());
        const grouped = this.groupBy(data, movie => movie.imdbID);

        const groupArray = [];
        grouped.forEach(movie => {
          groupArray.push({
            imdbID: movie[0].imdbID,
            created: movie.map(x => x.created),
            users: movie.map(x => x.userId),
            reviews: movie.map(x => x.review),
            ratings: movie.map(x => x.rating),
            displayNames: movie.map(x => {
              return {
                displayName: x.displayName,
                id: x.userId,
                picture: this.props.friends.find(
                  friend => friend.id === x.userId
                ).picture
              };
            }),
            services: movie.map(x => x.service)
          });
        });

        this.setState({
          groupedMovies: groupArray
        });
      } else {
        console.error("Not found");
      }
    });
  };

  getFriendsRating = ratings => {
    const count = {
      up: 0,
      down: 0
    };

    ratings.forEach(x => {
      switch (x) {
        case "up":
          count.up = ++count.up;
          break;
        case "down":
          count.down = ++count.down;
          break;
        default:
          break;
      }
    });

    return count.up === count.down ? (
      <>
        are <span className="split">SPLIT</span> on this
      </>
    ) : count.up > count.down ? (
      <>
        think this is a <span className="good">GOOD</span>
      </>
    ) : (
      <>
        think this is a <span className="bad">BAD</span>
      </>
    );
  };

  sortBy = sortType => {
    let sortFunc;
    switch (sortType) {
      case "released":
        sortFunc = (a, b) => sortShows(a.elm, b.elm, new Date().getFullYear());
        break;
      case "reviewed":
        sortFunc = (a, b) => sortReviewed(a.foundMovies, b.foundMovies);
        break;
      case "rated":
        sortFunc = (a, b) => sortRated(a.foundMovies, b.foundMovies);
        break;
      default:
        break;
    }

    this.getCards(this.state.movieData, sortFunc);
    this.setState({ sortType });
  };

  openReview = showData => {
    this.setState({ showData, toggleGiveReview: !this.state.toggleGiveReview });
  };

  removeMovie = (imdbID, title) => {
    const { uid } = firebase.auth().currentUser;
    const group = this.props.user ? this.props.user.groups[0] : "";

    db.collection("userMovieList")
      .where("userId", "==", uid)
      .where("group", "==", group)
      .where("imdbID", "==", imdbID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
          console.log(`Deleted ${title}`);
        });

        db.collectionGroup("reviews")
          .where("group", "==", group)
          .where("userId", "==", uid)
          .get()
          .then(snapShot => {
            if (!snapShot.empty) {
              snapShot.docs.forEach(doc => doc.ref.delete());
            }
            console.log(`Deleted Review ${title}`);
          });

        Swal.fire("Bye-Bye", `You've removed ${title}.`, "error");
      })
      .then(() => {
        this.refresh();
      });
  };

  refresh = async () => {
    await this.getGroupedMovies();
    this.getMovieList();
  };

  getCards = async (
    data,
    sortMethod = (a, b) => sortShows(a.elm, b.elm, new Date().getFullYear())
  ) => {
    let msg;
    if (!this.props.groupID && data.length === 0 && !this.props.isWatchList) {
      msg = <div className="font-1-3 center"><br/>Your list is empty...go fill'er up.</div>;
    } else if (this.props.groupID && data.length === 0) {
      msg = (
        <div className="font-1-3 center">
          <br/>
          None of your friends have reviewed a show.
        </div>
      );
    } else if (this.props.isWatchList && this.props.watchlist.size === 0) {
      msg = (
        <div className="font-1-3">
          You haven't saved any shows to your WatchList. Click the{" "}
          <i
            className="fad fa-eye"
            style={{
              "--fa-primary-color": "lightblue",
              "--fa-secondary-color": "lightgray",
              "--fa-secondary-opacity": "1.0"
            }}
          ></i>{" "}
          on a friend's show to add.
        </div>
      );
    }

    if (msg) {
      return this.setState({ cards: msg });
    }

    const tempWatchList = new Set(Array.from(this.props.watchlist));
    const resultPre = data
      .map((elm, i) => {
        const foundMovies = this.state.groupedMovies.find(
          x => x.imdbID === elm.imdbID
        );

        tempWatchList.delete(elm.imdbID);

        return { foundMovies, elm };
      })
      .sort(sortMethod);

    const result = resultPre.map((x, i) => {
      return this.getMappedCard(x.foundMovies, x.elm, i);
    });

    let resultWatch = [];
    if (this.props.isWatchList && tempWatchList.size > 0) {
      resultWatch = Array.from(tempWatchList).map(async (elm, i) => {
        return await this.props.getMovieDetail(elm).then(async () => {
          const addWatch = {
            imdbID: elm,
            title: this.props.item.Title,
            poster: this.props.item.Poster,
            plot: this.props.item.Plot,
            year: this.props.item.Year,
            type: this.props.item.Type,
            episode: this.props.item.Episode,
            season: this.props.item.Season
          };

          if (this.props.item.seriesID) {
            const previousTitle = this.props.item.Title;

            return await this.props
              .getMovieDetail(this.props.item.seriesID)
              .then(() => {
                addWatch.title = `${this.props.item.Title}: ${previousTitle}`;
                return this.getMappedCard(null, addWatch, i + "watch");
              });
          } else {
            return this.getMappedCard(null, addWatch, i + "watch");
          }
        });
      });
    }

    this.setState({ cards: await Promise.all(result.concat(resultWatch)) });
  };

  getMappedCard = (foundMovies, elm, i) => {
    const displayNames =
      foundMovies && foundMovies.displayNames
        ? foundMovies.displayNames.map((friend, i) => (
            <span key={i}>
              <img
                src={
                  friend.picture
                    ? friend.picture
                    : `https://api.adorable.io/avatars/50/${friend.id}.png`
                }
                className="user"
                alt={friend.displayName}
              />
              {friend.displayName}
              {foundMovies.displayNames.length > i + 1 ? (
                <>&nbsp;&nbsp;-&nbsp;&nbsp;</>
              ) : (
                ""
              )}
            </span>
          ))
        : [];
    const reviews =
      foundMovies && foundMovies.reviews ? foundMovies.reviews : [];
    const ratings =
      foundMovies && foundMovies.ratings ? foundMovies.ratings : [];

    const servicesIDs =
      foundMovies && foundMovies.services ? foundMovies.services : [];
    const servicesSet = new Set(
      servicesIDs
        .filter(el => {
          return el != null;
        })
        .map(x => SERVICES.get(x))
    );

    const services = Array.from(servicesSet).map((x, i) => {
      if (x === null || x === undefined) return "";

      const formattedService = formatService(x);
      return (
        <span key={i}>
          <img
            className="service-img"
            src={serviceImages(`./${formattedService}.png`)}
            alt={x}
            id={`${formattedService}${i}`}
          />
          <UncontrolledTooltip
            placement="top"
            target={`${formattedService}${i}`}
          >
            {x}
          </UncontrolledTooltip>
        </span>
      );
    });

    const inWatchList = this.props.watchlist.has(elm.imdbID);

    return (
      <span key={i}>
        {(!this.props.isWatchList || inWatchList) && (
          <MDBCol style={{ paddingBottom: "20px" }}>
            <MDBCard
              style={{ width: isMobile ? "24rem" : "62rem", height: "auto" }}
            >
              <MDBCardBody>
                <Row>
                  <Link to={`/detail/${elm.imdbID}`}>
                    <MDBCardImage
                      className="img-fluid cardDisplay-img"
                      src={elm.poster}
                      waves
                    />
                  </Link>
                  <Col>
                    <MDBCardTitle>
                      {elm.seriesTitle ? `${elm.seriesTitle}: ` : ""}
                      {elm.title}
                      {elm.episode ? `  S${elm.season}E${elm.episode}` : ""}
                      {(this.props.groupID || this.props.isWatchList) && (
                        <>
                          {"  "}
                          <EyeComponent
                            imdbID={elm.imdbID}
                            title={elm.title}
                            poster={elm.poster}
                            type={elm.type}
                            i={i}
                            callback={() => this.getMovieList()}
                          />
                        </>
                      )}
                      {!this.props.groupID && !this.props.isWatchList && (
                        <>
                          <span
                            onClick={() =>
                              this.removeMovie(elm.imdbID, elm.title)
                            }
                            className="pointer"
                          >
                            <i
                              className="fal fa-trash-alt trash"
                              id={`trashToolTip${i}`}
                            ></i>
                          </span>
                          <UncontrolledTooltip
                            placement="top"
                            target={`trashToolTip${i}`}
                          >
                            Remove from your list
                          </UncontrolledTooltip>
                        </>
                      )}
                    </MDBCardTitle>
                    <MDBCardText className="font-1">
                      {displayNames.length > 0 && (
                        <>
                          {" "}
                          <span>
                            Your friends {this.getFriendsRating(ratings)} show.
                          </span>
                          <br />
                          <br />
                          <span>Friends who reviewed: {displayNames}</span>
                          {services.length > 0 && (
                            <>
                              <br />
                              <span className="line-watched">
                                {!this.props.groupID && elm.service ? (
                                  <>
                                    You watched on:{" "}
                                    <img
                                      className="service-img"
                                      src={serviceImages(
                                        `./${mapService(elm.service)}.png`
                                      )}
                                      alt={SERVICES.get(elm.service)}
                                      id={`${mapService(elm.service)}a`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`${mapService(elm.service)}a`}
                                    >
                                      {SERVICES.get(elm.service)}
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <>Watched on: {services}</>
                                )}
                              </span>
                            </>
                          )}
                          <br />
                          {!this.props.groupID && (
                            <>
                              <span>
                                Your rating:{" "}
                                {elm.rating === "up" ? (
                                  <i className="fal fa-thumbs-up good"></i>
                                ) : (
                                  <i className="fal fa-thumbs-down bad"></i>
                                )}
                              </span>
                              <br />
                            </>
                          )}{" "}
                        </>
                      )}
                      <br />
                      About: {elm.plot} ({elm.year})
                      <br />
                      <br />
                    </MDBCardText>
                    <div>
                      {(this.props.groupID || this.props.isWatchList) && (
                        <MDBBtn
                          color=" cyan lighten-1"
                          onClick={() =>
                            this.openReview({
                              Title: elm.title,
                              Poster: elm.poster,
                              Plot: elm.plot,
                              Year: elm.year,
                              Type: elm.type,
                              imdbID: elm.imdbID
                            })
                          }
                          style={{ marginBottom: "1rem" }}
                          className="font-1"
                        >
                          <span className="white-color">Give your review</span>
                        </MDBBtn>
                      )}
                      {displayNames.length > 0 && (
                        <ReviewsComponent
                          reviews={reviews}
                          displayNames={displayNames}
                          servicesIDs={servicesIDs}
                          ratings={ratings}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        )}
      </span>
    );
  };

  handleSwitchChange = name => event => {
    this.setState(
      {
        ...this.state,
        [name]: event.target.checked,
        filterMyMovies: !this.state.filterMyMovies
      },
      () => this.getMovieList()
    );
  };

  toggleHandleReview = () => {
    this.setState({
      toggleGiveReview: !this.state.toggleGiveReview
    });
  };

  reviewedToggle = () => {
    this.refresh();
  };

  render() {
    const length = this.props.isWatchList
      ? this.props.watchlist.size
      : this.state.movieLength;

    return (
      <>
        <HeaderComponent />
        <br />
        <br />
        <div style={{ width: isMobile ? "24rem" : "62rem", height: "auto" }}>
          <h3 className="float-left">
            {!this.state.loading && (
              <span
                onClick={() => this.props.history.goBack()}
                className="pointer color-aqua"
              >
                search <i className="fal fa-angle-left"></i>
                {"  "}
              </span>
            )}
            {this.state.loading
              ? ""
              : this.props.title
              ? this.props.title
              : `${firebase.auth().currentUser.displayName}'s Shows`}
            {!this.state.loading && ` (${length})`}
          </h3>
          {!this.state.loading && this.props.groupID && (
            <span
              className={
                isMobile
                  ? "float-left margin-toggle"
                  : "float-right margin-toggle"
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.checkedSwitch}
                    onChange={this.handleSwitchChange("checkedSwitch")}
                    value="checkedSwitch"
                    color="primary"
                  />
                }
                label="Filter out my reviews"
              />
            </span>
          )}
          <br />
          <br />
          <br />
          {!this.state.loading && !this.props.isWatchList && (
            <>
              <br />
              <br />
              <Row className="search-form-container inherit-position">
                <MDBBtnGroup className="mr-2" size={isMobile ? "sm" : "lg"}>
                  <MDBBtn
                    color="cyan lighten-2"
                    onClick={() => this.sortBy("released")}
                    className={
                      this.state.sortType === "released" ? "" : "opacity-button"
                    }
                  >
                    Recently Released
                  </MDBBtn>
                  <MDBBtn
                    color="cyan lighten-2"
                    onClick={() => this.sortBy("reviewed")}
                    className={
                      this.state.sortType === "reviewed" ? "" : "opacity-button"
                    }
                  >
                    Recently Reviewed
                  </MDBBtn>
                  <MDBBtn
                    color="cyan lighten-2"
                    onClick={() => this.sortBy("rated")}
                    className={
                      this.state.sortType === "rated" ? "" : "opacity-button"
                    }
                  >
                    Highest Rated
                  </MDBBtn>
                </MDBBtnGroup>
              </Row>
            </>
          )}
          <SubmitReviewComponent
            data={this.state.showData}
            show={this.state.toggleGiveReview}
            handleClose={() => this.toggleHandleReview()}
            callback={() => this.reviewedToggle()}
            refresh={() => this.refresh()}
          />
          <div className="float-left">
            <PulseLoader
              sizeUnit={"px"}
              size={26}
              color={"red"}
              loading={this.state.loading}
            />
          </div>
          {!this.state.loading ? this.state.cards : ""}
          <div className="padding-footer" />
          <FooterComponent />
        </div>
      </>
    );
  }
}

const UserMovieListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMovieList);

export default withRouter(UserMovieListComponent);
