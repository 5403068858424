import React from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { getUser } from "../actions";
import firebase from "../base";

import UserMovieListComponent from "./UserMovieListComponent.jsx";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(getUser(payload))
  };
};

class WatchList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group: ''
    }
  }
  
  componentDidMount() {
    this.props.getUser(firebase.auth().currentUser.uid).then(() => {
      this.setState({ group: this.props.user ? this.props.user.groups[0] : '' });
    });
  }

  render() {
    return <UserMovieListComponent isWatchList={true} title="Watch List"/>;
  }
}

const WatchListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchList);
export default withRouter(WatchListComponent);
