import React from "react";

import { withRouter, Link } from "react-router-dom";
import { MDBCard, MDBCollapse } from "mdbreact";
import { Row, Col, UncontrolledTooltip, Tooltip } from "reactstrap";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";

import firebase, { db } from "../base";

import { connect } from "react-redux";
import { PROD_APP_URL, isProd, SUPPORT_EMAIL } from "../utils/variables.js";
import { getUser, getFriends } from "../actions";

import logo from "../assets/img/TGF-logo.png";
import friends from '../assets/img/friends.jpg';

const mapStateToProps = state => {
  return {
    user: state.user,
    friends: state.friends
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(getUser(payload)),
    getFriends: payload => dispatch(getFriends(payload))
  };
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapseID: "",
      tooltipOpen: false
    };
  }

  componentDidMount() {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      this.props.getUser(currentUser.uid).then(() => {
        this.props
          .getFriends(this.props.user ? this.props.user.groups[0] : "")
          .then(() => {
            this.setState({
              friends: this.props.friends
                ? this.props.friends.map((friend, i) => (
                    <span key={i}>
                      <img
                        src={
                          friend.picture
                            ? friend.picture
                            : `https://api.adorable.io/avatars/50/${friend.id}.png`
                        }
                        className="user"
                        alt={friend.displayName}
                      />
                      {friend.displayName}
                      <br />
                    </span>
                  ))
                : []
            });
          });
      });
    } else {
      console.log("Not authenticated");
    }
  }

  showInvite = () => {
    const { displayName } = firebase.auth().currentUser;

    const sweetData = {};
    sweetData.confirmButtonText = "Invite Friend";
    sweetData.input = "email";
    sweetData.inputAttributes = {
      autocapitalize: "off"
    };
    sweetData.inputPlaceholder = "Friend's email...";
    sweetData.title = "Invite Your Friend";
    sweetData.text =
      "Enter your friend's email address and we'll invite them to join your group...maybe Joey, Ross, Rachel, or Chandler?";
    sweetData.cancelButtonText = "later";
    sweetData.showCancelButton = true;
    sweetData.showLoaderOnConfirm = true;
    sweetData.reverseButtons = true;
    sweetData.imageUrl = friends;
    sweetData.imageAlt = 'Friends';
    sweetData.preConfirm = email => {
      const mailCollection = db.collection("mail");
      const emailData = {
        template: {
          name: "invite_friend_header",
          data: {
            domain: process.env.REACT_APP_DOMAIN,
            invite_url: `${PROD_APP_URL}/signup?group=${encodeURIComponent(
              this.props.user.groups[0]
            )}`,
            friend_url: `mailto:?subject=Join me on theGoodflix&body=We can start sharing our shows on theGoodflix.%0D%0A%0D%0AHere is the signup: ${PROD_APP_URL}/signup?group=${encodeURIComponent(
              encodeURIComponent(this.props.user.groups[0])
            )}`,
            displayName
          }
        }
      };
      emailData.to = email;

      if (isProd) {
        // in prod BCC
        console.log("Is Prod");
        emailData.bcc = SUPPORT_EMAIL;
      }

      mailCollection.add(emailData);
    };
    sweetData.allowOutsideClick = false;

    Swal.fire(sweetData).then(result => {
      if (result && result.dismiss !== "cancel") {
        Swal.fire({
          icon: "success",
          text: "Your friend is about to have the best day ever!",
          imageUrl: ""
        });
      }
    });
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  toggleTooltip = () =>
    this.setState({
      tooltipOpen: !this.state.collapseID && !this.state.tooltipOpen
    });

  render() {
    return (
      <Row>
        <Col>
          <span className="float-left front">
            <Link to="/" alt="Home">
              <img src={logo} alt="theGoodflix Logo" className="logo" />
            </Link>
          </span>
        </Col>
        {firebase.auth().currentUser && (
          <Col className="front">
            <span className="float-right">
              <span
                color="primary"
                onClick={this.toggleCollapse("basicCollapse")}
                className="btn-link friends-nav"
                id="friends"
              >
                <i className="fad fa-users"></i>
              </span>
              <span
                color="elegant"
                className="btn-md pointer logout"
                onClick={() =>
                  firebase
                    .auth()
                    .signOut()
                    .then(() => window.location.reload())
                }
                id="logout"
              >
                <i className="fal fa-sign-out"></i>
              </span>{" "}
              <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
                <MDBCard className="friendsList">
                  <p className="friends">{this.state.friends}</p>
                </MDBCard>
              </MDBCollapse>
            </span>
            <span className="float-right2">
              <span
                color="primary"
                onClick={this.showInvite}
                className="btn-link invite-friend"
                id="invite_friend"
              >
                <i className="fab fa-suse"></i>
              </span>
            </span>
            {!isMobile && (
              <>
                <UncontrolledTooltip placement="bottom" target="logout">
                  Logout
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" target="invite_friend">
                  Invite a friend
                </UncontrolledTooltip>
                <Tooltip
                  placement="bottom"
                  isOpen={this.state.tooltipOpen}
                  target="friends"
                  toggle={this.toggleTooltip}
                >
                  Friends in {this.props.user ? this.props.user.groups[0] : ""}
                </Tooltip>
              </>
            )}
          </Col>
        )}
      </Row>
    );
  }
}

const HeaderComponent = connect(mapStateToProps, mapDispatchToProps)(Header);
export default withRouter(HeaderComponent);
