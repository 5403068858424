import React, { useEffect, useState } from "react";
import firebase, { db } from "./base.js";

export const AuthContext = React.createContext({
  user: null
});

export const AuthProvider = ({ children }) => {
  const useAuth = () => {
    const [state, setState] = useState(() => {
      const currentUser = firebase.auth().currentUser;
      return { initializing: !currentUser, currentUser };
    });
    
    function onChange(currentUser) {
      setState({ initializing: false, currentUser });
      
      if (currentUser) {
        db.collection("last_access").doc().set({
          uid: currentUser.uid,
          displayName: currentUser.displayName,
          utm: 'web',
          last_access: new Date()
        });
      }
    }

    useEffect(() => {
      // listen for auth state changes
      const unsubscribe = firebase.auth().onAuthStateChanged(onChange);

      // unsubscribe to the listener when unmounting
      return () => unsubscribe();
    }, []);

    return state;
  };

  //function App() {
  const { initializing, currentUser } = useAuth();
  if (initializing) {
    return (
      <div className="divLoader">
        <svg
          className="svgLoader"
          viewBox="0 0 100 100"
          width="10em"
          height="10em"
        >
          <path
            ng-attr-d="{{ config.pathCmd }}"
            ng-attr-fill="{{ config.color }}"
            stroke="none"
            d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
            fill="#51CACC"
            transform="rotate(179.719 50 51)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              calcMode="linear"
              values="0 50 51;360 50 51"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            ></animateTransform>
          </path>
        </svg>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
  // }
};
