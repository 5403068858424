import React from "react";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { MDBCard, MDBBtn } from "mdbreact";
import { Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";

import { getMovieDetail } from "../actions";
import HeaderComponent from "./HeaderComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";

import firebase from "../base.js";

import rt from "../assets/img/rt/rt.png";
import splat from "../assets/img/rt/splat.png";
import blank from "../assets/img/rt/blank.png";

const mapStateToProps = state => {
  return { item: state.item };
};

const mapDispatchToProps = dispatch => {
  return {
    getMovieDetail: payload => dispatch(getMovieDetail(payload))
  };
};

class MovieDetail extends React.Component {
  image() {
    if (!this.props.item.Poster || this.props.item.Poster !== "N/A") {
      return <img src={this.props.item.Poster} className="img-fluid" alt="" />;
    } else {
      return;
    }
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.props.getMovieDetail(params.imdbID);
    
    /*.then(() => {
      fetch(`https://unogs.com/?q=${params.imdbID}`)
        .then((response) => {
          response.text().then(text => {
            console.log(text);
          });
        })
        .catch(function(err) {
          console.log(err);
        });
    });*/
  }

  render() {
    window.scroll(0, 0);

    const rottenTomatoes = this.props.item.Ratings
      ? this.props.item.Ratings.find(elm => elm.Source === "Rotten Tomatoes")
      : null;

    return (
      <div>
        <HeaderComponent />
        <Helmet>
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="www.thegoodflix.com" />
          <meta
            name="twitter:title"
            content={`theGoodflix: ${this.props.item.Title}`}
          />
          <meta name="twitter:description" content={this.props.item.Plot} />
          <meta name="twitter:image" content={this.props.item.Poster} />

          <meta property="og:url" content="https://www.thegoodflix.com" />
          <meta
            property="og:title"
            content={`theGoodflix: ${this.props.item.Title}`}
          />
          <meta property="og:description" content={this.props.item.Plot} />
          <meta property="og:image" content={this.props.item.Poster} />
          <meta property="og:type" content="website" />
        </Helmet>
        <br />
        <Row className="margin-auto">
          <Col
            xs="12"
            sm="10"
            lg="4"
            className="card-margin"
            style={{ padding: "20px" }}
          >
            <div>
              <h2 className="page-title color-aqua">Show Details</h2>           
            </div>
            <MDBCard>
              <div className="view overlay hm-white-slight">
                <center>{this.image()}</center>
                <Link to={`/detail/${this.props.item.imdbID}`}>
                  <div className="mask"></div>
                </Link>
              </div>
              <br />
              <div className="card-block search-margin">
                <h4 className="card-title">{this.props.item.Title}</h4>
                <p className="card-text card-meta">
                  Released: {this.props.item.Year}, Type: {this.props.item.Type}
                </p>

                <strong>Plot</strong>
                <hr />
                <p className="card-text">{this.props.item.Plot}</p>

                <strong>Genre</strong>
                <hr />
                <p className="card-text">{this.props.item.Genre}</p>

                <strong>Director</strong>
                <hr />
                <p className="card-text">{this.props.item.Director}</p>

                <strong>Actors</strong>
                <hr />
                <p className="card-text">{this.props.item.Actors}</p>

                <strong>Rotten Tomatoes</strong>
                <hr />
                <p className="card-text">
                  <a
                    href={this.props.item.tomatoURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        rottenTomatoes
                          ? parseInt(rottenTomatoes.Value) >= 60
                            ? rt
                            : splat
                          : blank
                      }
                      className="rt"
                      alt={rottenTomatoes ? 'Rotten Tomato' : 'N/A'}
                    />{" "}
                    {rottenTomatoes ? rottenTomatoes.Value : 'N/A'}
                  </a>
                </p>

                <strong>IMDB Rating</strong>
                <hr />
                <p className="card-text">
                  <a
                    href={`https://www.imdb.com/title/${this.props.item.imdbID}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.item.imdbRating}
                  </a>
                  {/*{" "}
                  ({this.props.item.imdbVotes} Votes)*/}
                </p>

                <strong>Runtime</strong>
                <hr />
                <p className="card-text">{this.props.item.Runtime}</p>

                <strong>Rated</strong>
                <hr />
                <p className="card-text">{this.props.item.Rated}</p>

                <strong>Date Released</strong>
                <hr />
                <p className="card-text">{this.props.item.Released}</p>

                <strong>Country (Language)</strong>
                <hr />
                <p className="card-text">
                  {this.props.item.Country} ({this.props.item.Language})
                </p>

                <strong>Production</strong>
                <hr />
                <p className="card-text">{this.props.item.Production}</p>
                <br />
                <div className="read-more">
                  {firebase.auth().currentUser ? (
                    <MDBBtn
                      outline
                      onClick={() => this.props.history.goBack()}
                      className="btn btn-default"
                    >
                      Go Back
                    </MDBBtn>
                  ) : (
                    <Link to="/signup" alt="Sign Up">
                      <MDBBtn outline className="btn btn-default">
                        Sign Up to theGoodflix
                      </MDBBtn>
                    </Link>
                  )}
                </div>
                <br />
              </div>
            </MDBCard>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <FooterComponent />
      </div>
    );
  }
}

const MovieDetailComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovieDetail);
export default withRouter(MovieDetailComponent);
