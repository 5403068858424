import {
  API_URL,
  API_KEY,
  API_KEY_QUERY_STRING,
  START_FETCHING,
  SET_PAGE,
  SET_MOVIE_LIST,
  SET_MOVIE_DETAIL,
  SET_USER,
  SET_WATCHLIST,
  SET_FRIENDS
} from "./constants";

import firebase, { db } from "./base.js";

export function getMovieList(payload) {
  // window.scroll(0, 0);
  return dispatch => {
    dispatch({ type: START_FETCHING });
    dispatch({ type: SET_PAGE, payload: payload.page });

    let url;
    if(payload.imdbID) {
      url = `${API_URL}?${API_KEY_QUERY_STRING}=${API_KEY}&i=${payload.imdbID}&season=${[payload.season ? payload.season : 1]}`;
    } else {
      url = `${API_URL}?${API_KEY_QUERY_STRING}=${API_KEY}&s=${payload.q}&page=${payload.page}`;
    }

    return fetch(url)
      .then(result => result.json())
      .then(items => {
        dispatch({ type: SET_MOVIE_LIST, payload: items });
      });
  };
}

export function getMovieDetail(payload) {
  // window.scroll(0, 0);
  return dispatch => {
    dispatch({ type: START_FETCHING });

    return fetch(
      `${API_URL}?${API_KEY_QUERY_STRING}=${API_KEY}&i=${payload}&plot=short&r=json&tomatoes=true`
    )
      .then(result => result.json())
      .then(item => {
        dispatch({ type: SET_MOVIE_DETAIL, payload: item });
      })
      .catch(err => {
        console.error("Error fetching show data: " + err);
        dispatch({ type: SET_MOVIE_DETAIL, payload: [] });
      });
  };
}

export function getUser(payload) {
  // window.scroll(0, 0);
  return dispatch => {
    dispatch({ type: START_FETCHING });

    const { currentUser } = firebase.auth();

    return db
      .collection("users")
      .doc(payload ? payload : currentUser.uid)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          const data = querySnapshot.data();
          data.currentUser = currentUser;

          dispatch({ type: SET_USER, payload: data });
        } else {
          dispatch({ type: SET_USER, payload: null });
          console.log("error getting user");
        }
      });
  };
}

export function getFriends(group) {
  return dispatch => {
    dispatch({ type: START_FETCHING });

    return db
      .collection("users")
      .where("groups", "array-contains", group ? group : "")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const friends = querySnapshot.docs.map((doc, i) => {
            return { displayName: doc.data().displayName, id: doc.id, picture: doc.data().picture };
          });
          dispatch({ type: SET_FRIENDS, payload: friends ? friends : [] });
        });
      })
      .catch(err => console.log("Error getting friends " + err));
  };
}

export function getWatchList(payload) {
  // window.scroll(0, 0);
  return dispatch => {
    dispatch({ type: START_FETCHING });

    return db
      .collection("watchlist")
      .doc(payload)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists && !querySnapshot.empty) {
          const data = querySnapshot.data().imdb;
          dispatch({ type: SET_WATCHLIST, payload: new Set(data) });
        }
      });
  };
}

export function setWatchList(payload) {
  // window.scroll(0, 0);
  return dispatch => {
    return dispatch({ type: SET_WATCHLIST, payload: payload });
  };
}
