import React from "react";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { getMovieList } from "../actions";

const mapStateToProps = state => {
  return { items: state.items };
};

const mapDispatchToProps = dispatch => {
  return {
    getMovieList: payload => dispatch(getMovieList(payload))
  };
};

class Pagination extends React.Component {
  prevClick() {
    this.props.callback();
    this.props
      .getMovieList({
        q: this.props.q,
        page: Number(this.props.page) - 1
      })
      .then(() => this.props.callback());
  }

  nextClick() {
    this.props.callback();
    this.props
      .getMovieList({
        q: this.props.q,
        page: Number(this.props.page) + 1
      })
      .then(() => this.props.callback());
  }

  render() {
    return (
      <div className="margin-auto">
        {this.props.items.totalResults > 10 && (
          <div className="row pagination-container">
            <nav className="row text-xs-center">
              {Number(this.props.page) > 1 ? (
                <Link
                  to={`/movies?q=${this.props.q}&page=${Number(
                    this.props.page
                  ) - 1}`}
                  className="btn btn-md btn-primary"
                  onClick={() => this.prevClick(this.props.page)}
                >
                  {"< Previous"}
                </Link>
              ) : null}
              <Link
                to={`/movies?q=${this.props.q}&page=${Number(this.props.page) +
                  1}`}
                className="btn btn-md btn-primary"
                onClick={() => this.nextClick(this.props.page)}
              >
                {"Next >"}
              </Link>
            </nav>
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

const PaginationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Pagination);
export default PaginationComponent;
