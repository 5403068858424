import React from "react";

import { connect } from "react-redux";
import firebase from "../base";
import { getUser, getFriends } from "../actions";
import Show from "./Show.js";

import { API_URL, API_KEY, API_KEY_QUERY_STRING } from "../constants";

const mapStateToProps = state => {
  return {
    user: state.user,
    friends: state.friends
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(getUser(payload)),
    getFriends: payload => dispatch(getFriends(payload))
  };
};

class MovieItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      shareDisabled: true
    };
  }

  componentDidMount() {
    this.getPlot();
    // this.props.getUser(firebase.auth().currentUser.uid);

    if (firebase.auth().currentUser) {
      this.props.getUser(firebase.auth().currentUser.uid).then(() => {
        this.props.getFriends(this.props.user ? this.props.user.groups[0] : "");
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.getPlot();
    }

    /*Object.entries(this.props).forEach(([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
      );
      if (this.state) {
        Object.entries(this.state).forEach(([key, val]) =>
          prevState[key] !== val && console.log(`State '${key}' changed`)
        );
      }*/
  }

  getPlot = async () => {
    fetch(
      `${API_URL}?${API_KEY_QUERY_STRING}=${API_KEY}&i=${this.props.data.imdbID}&plot=short&r=json&tomatoes=true`
    )
      .then(result => result.json())
      .then(item => {
        this.setState({
          plot: item.Plot !== "N/A" ? item.Plot : "",
          item
        });
      })
      .catch(err => {
        console.error("Error fetching show data: " + err);
      });
  };

  render() {
    if (this.state.plot) {
      const show = { ...this.props.data };
      show.Poster = this.state.item.Poster;
      show.Year = this.state.item.Year;
      show.Season = this.state.item.Season;
      show.Episode = this.state.item.Episode;
      show.Type = this.state.item.Type;

      show.reviewed = this.props.reviewed;
      show.i = this.props.i;
      show.friends = this.props.friends ? this.props.friends.length : 0;
      show.Plot = this.state.plot;

      return Show(show);
    } else {
      return <></>;
    }
  }
}

const MovieItemComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovieItem);
export default MovieItemComponent;
