import React from "react";
import { withRouter } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import { Collapse, Card, CardBody } from "reactstrap";
import { SERVICES } from "../utils/variables.js";

class ReviewsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false
    };
  }

  toggleMore = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  render() {
    return (
      <>
        <MDBBtn
          color="primary"
          onClick={this.toggleMore}
          style={{ marginBottom: "1rem" }}
          className="font-1"
        >
          {this.state.collapse ? (
            <>
              Close&nbsp;&nbsp;
              <i className="fal fa-angle-up"></i>
            </>
          ) : (
            <>
              See All Reviews&nbsp;&nbsp;
              <i className="fal fa-angle-down"></i>
            </>
          )}
        </MDBBtn>
        <Collapse isOpen={this.state.collapse}>
          {this.props.reviews.map((review, i) => (
            <span key={i}>
              <Card>
                <CardBody>
                  <b>{this.props.displayNames[i]}</b> watched on{" "}
                  <i>{SERVICES.get(this.props.servicesIDs[i])}</i> and gave a{" "}
                  {this.props.ratings[i] === "up" ? (
                    <i className="fal fa-thumbs-up good"></i>
                  ) : (
                    <i className="fal fa-thumbs-down bad"></i>
                  )}
                  <br />
                  <br />
                  {"Review: "}
                  {review}
                </CardBody>
              </Card>
              <br />
            </span>
          ))}
        </Collapse>
      </>
    );
  }
}

export default withRouter(ReviewsComponent);
