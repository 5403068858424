import { SERVICES } from './variables.js';
import firebase from '../base.js';

const formatService = x => {
  return x
    ? x
        .replace(/\s/g, "")
        .replace("+", "")
        .toLowerCase()
    : "";
};

const mapService = service => {
  return formatService(SERVICES.get(service));
};

const shortenTwitter = (title, review) => {
  const twitterMax = 280;
  const charCount = 82 + title.length; // our base of 82
  const over = twitterMax - charCount - review.length;

  let reviewPro = review;
  if (over < 0) {
    reviewPro = reviewPro.slice(0, over - 3) + "...";
  }

  return reviewPro;
};

const isNewUser = () => {
  const { currentUser } = firebase.auth();
  return currentUser ? (currentUser.metadata.creationTime === currentUser.metadata.lastSignInTime) : false;
};

export {formatService, mapService, shortenTwitter, isNewUser };
