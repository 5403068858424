import React from "react";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import qs from "qs";

import HeaderComponent from "./HeaderComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";
import ShowsComponent from "./ShowsComponent.jsx";

class PopularShowsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      length: 5
    };
  }

  render() {
    const { s, l } = qs.parse(window.location.search.slice(1));

    return (
      <>
        <HeaderComponent />
        <div>
          <br />
          <br />
          <br />
          <ShowsComponent type={s} length={l ? l : 100} showHeader={true} />
          <Container>
            <br />
            <br />
          </Container>
          <br />
          <br />
          <FooterComponent />
        </div>
      </>
    );
  }
}

export default withRouter(PopularShowsComponent);
