import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";
import { withRouter } from "react-router-dom";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { connect } from "react-redux";
import { getUser } from "../actions";
import firebase from "../base";
import { PROD_APP_URL } from "../utils/variables.js";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(getUser(payload))
  };
};

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group: "",
      copied: false
    };
  }

  componentDidMount() {
    if (firebase.auth().currentUser) {
      this.props.getUser(firebase.auth().currentUser.uid).then(() => {
        this.setState({
          group: this.props.user ? this.props.user.groups[0] : ""
        });
      });
    } else {
      console.log("Not Authenticated Footer");
    }
  }

  render() {
    const rss = `https://app.thegoodflix.com/getWatchlist?uid=${
      firebase.auth().currentUser.uid
    }&type=`;

    return (
      <MDBFooter color="transparent" className="font-small pt-4 mt-4">
        <div className="footer-copyright text-center py-3 transparent-footer">
          <MDBContainer fluid>
            <span className="footer-txt">
              &copy; {new Date().getFullYear()} theGoodflix
              <br />
              <a
                href="mailto:support@thegoodflix.com?subject=Feedback"
                className="footer-txt"
              >
                {" "}
                Feedback
              </a>{" "}
              -{" "}
              <a
                href={`mailto:?subject=Join me on theGoodflix&body=We can start sharing our shows on theGoodflix.%0D%0A%0D%0AHere is the signup: ${PROD_APP_URL}/signup?group=${encodeURIComponent(
                  encodeURIComponent(this.state.group)
                )}`}
                className="footer-txt blue-color"
              >
                Share with a Friend
              </a>{" "}
              -{" "}
              <CopyToClipboard
                text={rss + "movie"}
                onCopy={() => this.setState({ copied: true })}
              >
                <span className="pointer">Movies RSS</span>
              </CopyToClipboard>{" "}
              -{" "}
              <CopyToClipboard
                text={rss + "series"}
                onCopy={() => this.setState({ copied: true })}
              >
                <span className="pointer">TV RSS</span>
              </CopyToClipboard>
              {this.state.copied ? (
                <>
                  <br />
                  <span className="color-red">Copied to Clipboard</span>
                </>
              ) : (
                ""
              )}
            </span>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}

const FooterComponent = connect(mapStateToProps, mapDispatchToProps)(Footer);
export default withRouter(FooterComponent);
