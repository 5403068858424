import React from "react";
import { connect } from "react-redux";
import qs from "qs";
import { withRouter } from "react-router-dom";
// import { isMobile } from "react-device-detect";
import { Element, scroller } from "react-scroll";

import {
  Row,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import PulseLoader from "react-spinners/PulseLoader";

import MovieItemComponent from "./MovieItemComponent.jsx";
import PaginationComponent from "./PaginationComponent.jsx";
import SearchFormComponent from "./SearchFormComponent.jsx";
import HeaderComponent from "./HeaderComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";

import { getMovieList, getUser } from "../actions";
import firebase, { db } from "../base.js";

const mapStateToProps = state => {
  return { page: state.page, items: state.items, user: state.user };
};

const mapDispatchToProps = dispatch => {
  return {
    getMovieList: payload => dispatch(getMovieList(payload)),
    getUser: payload => dispatch(getUser(payload))
  };
};

class MovieList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: false,
      dirtyHP: false,
      dropDownValue: "Season 1",
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    let search = this.props.location.search.slice(1);
    let parsed = qs.parse(search);

    this.props
      .getMovieList({
        q: parsed.q,
        imdbID: parsed.i,
        page: parsed.page ? parsed.page : 1
      })
      .then(() => this.setState({ loading: false }));

    this.unlisten = this.props.history.listen((location, action) => {
      let search = location.search.slice(1);
      let parsed = qs.parse(search);
      if (!parsed.page) { 
        this.setState({ loading: true });
        this.props
          .getMovieList({
            q: parsed.q,
            imdbID: parsed.i,
            page: parsed.page ? parsed.page : 1
          })
          .then(() => this.setState({ loading: false }));
      }
    });

    this.getReviewed();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({ scroll: true });
      this.getReviewed();
    }
  }

  scrollTo = () => {
    if (
      !this.props.loading &&
      (this.state.scroll || this.props.location.search.includes("hp"))
    ) {
      setTimeout(() => {
        scroller.scrollTo("scroll-container", {
          duration: 500,
          delay: 0,
          smooth: "easeInQuad"
        });
      }, 200);
    }
  };

  getReviewed = () => {
    const { uid } = firebase.auth().currentUser;
    this.props.getUser(uid).then(() => {
      db.collection("userMovieList")
        .where("userId", "==", uid)
        .where("group", "==", this.props.user ? this.props.user.groups[0] : "")
        .get()
        .then(querySnapshot => {
          const dataDB = querySnapshot.docs.map(doc => doc.data());
          const unique = [...new Set(dataDB.map(item => item.imdbID))];

          const userMovies = [];
          unique.forEach(id => {
            const find = dataDB.find(x => id === x.imdbID && uid === x.userId);
            if (find) userMovies.push(find);
          });
          const imdbIDs = userMovies.map(u => u.imdbID);

          this.setState({
            imdbIDs
          });
        })
        .catch(error => {
          console.error("Error getting documents: ", error);
        });
    });
  };

  getDropDown = parsed => {
    const arrayReturn = [];
    for (let i = 0; i < parseInt(this.props.items.totalSeasons); i++) {
      arrayReturn.push(
        <DropdownItem key={i} onClick={e => this.setSeason(parsed, i + 1)}>
          Season {i + 1}
        </DropdownItem>
      );
    }

    return arrayReturn;
  };

  setSeason = (parsed, season) => {
    this.setState({ loading: true });
    this.props
      .getMovieList({
        q: parsed.q,
        imdbID: parsed.i,
        season,
        page: this.props.page
      })
      .then(() => this.setState({ loading: false }));
    this.setState({ dropDownValue: `Season ${season}` });
  };

  switchLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  render() {
    const search = this.props.location.search.slice(1);
    const parsed = qs.parse(search);

    let movieList;
    let seriesTitle;
    if (
      (parsed.i && this.props.items.Episodes) ||
      (!parsed.i && this.props.items.Search)
    ) {
      let mapList;
      if (parsed.i) {
        mapList = this.props.items.Episodes;
        seriesTitle = this.props.items.Title;
      } else {
        mapList = this.props.items.Search;
      }

      movieList = mapList.map((item, i) => {
        if (seriesTitle) {
          item.seriesTitle = seriesTitle;
        }

        return (
          <Row key={i}>
            <MovieItemComponent
              key={i}
              i={i}
              data={item}
              reviewed={
                this.state.imdbIDs && this.state.imdbIDs.includes(item.imdbID)
              }
            />
          </Row>
        );
      });
    } else {
      movieList = (
        <div className="alert alert-info" role="alert">
          Sorry, but we couldn't find anything!
        </div>
      );
    }

    return (
      <>
        <HeaderComponent />
        <div>
          <br />
          <br />
          <br />
          <Container className="search-form-container-list">
            <Row className="search-form-container inherit-position">
              <SearchFormComponent colClass={"home"} />
            </Row>
            <Element className="element" id="scroll-container" />
            <br />
            <br />
            <Row className="center-card" id="first">
              <PulseLoader
                sizeUnit={"px"}
                size={26}
                color={"red"}
                loading={this.state.loading}
              />
            </Row>
            {!this.state.loading && (
              <>
                {this.scrollTo()}
                <Row className="center-card" id="first">
                  <h3 className="center extra-margin">
                    {parsed.q
                      ? `Found "${parsed.q}" shows...`
                      : `Episodes: ${seriesTitle}`}
                  </h3>
                  {parsed.i && (
                    <UncontrolledDropdown required>
                      <DropdownToggle color="cyan-light" caret>
                        {this.state.dropDownValue}
                      </DropdownToggle>
                      <DropdownMenu>{this.getDropDown(parsed)}</DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </Row>
                <Row className="center-card" id="first">
                  {movieList}
                </Row>
              </>
            )}
          </Container>

          <Container>
            <br />
            <br />
            <PaginationComponent
              q={parsed.q}
              page={parsed.page ? parsed.page : 1}
              callback={() => this.switchLoading()}
            />
          </Container>
          <br />
          <br />
          <FooterComponent />
        </div>
      </>
    );
  }
}

const MovieListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovieList);
export default withRouter(MovieListComponent);
