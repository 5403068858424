import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { MDBBtnGroup, MDBBtn } from "mdbreact";
import { isMobile } from "react-device-detect";
import qs from "qs";
import Swal from "sweetalert2";

import SearchFormComponent from "./SearchFormComponent.jsx";
import HeaderComponent from "./HeaderComponent.jsx";
import FooterComponent from "./FooterComponent.jsx";
import ShowsComponent from "./ShowsComponent.jsx";

import { PROD_APP_URL, isProd, SUPPORT_EMAIL } from "../utils/variables.js";
import firebase, { db } from "../base";
import { getUser, getFriends } from "../actions";

import pb from "../assets/img/pb.jpg";
import deadpool from "../assets/img/deadpool.jpg";

const mapStateToProps = state => {
  return {
    user: state.user,
    friends: state.friends
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(getUser(payload)),
    getFriends: payload => dispatch(getFriends(payload))
  };
};

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: <ShowsComponent type="popTV" length={50} />,
      type: "popTV"
    };
  }

  componentDidMount() {
    const { wl } = qs.parse(window.location.search.slice(1));
    if (wl) {
      this.addWatchList(wl);
    }

    this.props.getUser().then(() => {
      this.props
        .getFriends(this.props.user ? this.props.user.groups[0] : "")
        .then(() => {
          if (this.props.friends.length < 2) {
            // this.showInvite();
          }
        });
    });
  }

  addWatchList = imdbID => {
    const { uid } = firebase.auth().currentUser;
    db.collection("watchlist")
      .doc(uid)
      .update({
        imdb: firebase.firestore.FieldValue.arrayUnion(imdbID)
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "You've Added to Your Watch List",
          icon: "success",
          cancelButtonText: "close",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "See Watch List"
        }).then(result => {
          if (result.value) {
            this.props.history.push("/watchlist");
          }
        });
      });
  };

  showPopular = type => {
    this.setState({
      show: <ShowsComponent type={type} length={50} />,
      type
    });
  };

  showInvite = () => {
    const { displayName } = firebase.auth().currentUser;
    const htmlText = `You need <u>at least one friend</u> to join your group to use theGoodflix.<br/><br/>Send them this link<br/><span class="color-aqua font-0875">${PROD_APP_URL}/signup?group=${encodeURIComponent(
      encodeURIComponent(this.props.user.groups[0])
    )}</span><br/><br/>Or enter your friend's email address and we'll invite them to join...maybe Joey, Ross, Rachel, or Chandler?`;

    const sweetData = {};
    sweetData.confirmButtonText = "Invite Friend";
    sweetData.input = "email";
    sweetData.inputAttributes = {
      autocapitalize: "off"
    };
    sweetData.inputPlaceholder = "Friend's email...";
    sweetData.title = "Welcome to theGoodflix";
    sweetData.html = htmlText;
    sweetData.cancelButtonText = "later";
    sweetData.showCancelButton = false;
    sweetData.showLoaderOnConfirm = true;
    sweetData.imageUrl = pb;
    sweetData.imageAlt = "Welcome to TGF";
    sweetData.customClass = {
      container: "my-swal"
    };
    sweetData.reverseButtons = true;
    sweetData.preConfirm = email => {
      const mailCollection = db.collection("mail");
      const emailData = {
        template: {
          name: "invite_friend_header",
          data: {
            domain: process.env.REACT_APP_DOMAIN,
            invite_url: `${PROD_APP_URL}/signup?group=${encodeURIComponent(
              this.props.user.groups[0]
            )}`,
            friend_url: `mailto:?subject=Join me on theGoodflix&body=We can start sharing our shows on theGoodflix.%0D%0A%0D%0AHere is the signup: ${PROD_APP_URL}/signup?group=${encodeURIComponent(
              encodeURIComponent(this.props.user.groups[0])
            )}`,
            displayName
          }
        }
      };
      emailData.to = email;

      if (isProd) {
        // in prod BCC
        console.log("Is Prod");
        emailData.bcc = SUPPORT_EMAIL;
      }

      mailCollection.add(emailData);
    };
    sweetData.allowOutsideClick = false;

    Swal.fire(sweetData).then(result => {
      if (result && result.dismiss !== "cancel") {
        this.goHome();
      }
    });
  };

  goHome = () => {
    Swal.fire({
      icon: "success",
      html:
        "Your friend is about to have the best day ever!<br/><br/>Now go-on, get going. We'll let you know when they join.",
      allowOutsideClick: false
    }).then(() => {
      Swal.fire({
        text: "You're still here? It's over. Go home. We'll let you know.",
        imageUrl: deadpool,
        imageAlt: "Go Home",
        allowOutsideClick: false
      }).then(() => this.goHome());
    });
  };

  render() {
    return (
      <>
        <HeaderComponent />
        <div>
          <br />
          <br />
          <br />
          <Container className="search-form-container-list">
            <Row className="search-form-container inherit-position">
              <h3>
                <center>
                  <span className="welcome">
                    What is a recent movie or show you watched?
                    <br />
                    Search for it and give a review.
                  </span>
                </center>
              </h3>
            </Row>
            <br />
            <br />
            <Row className="search-form-container inherit-position">
              <SearchFormComponent colClass={"home"} source="home" />
            </Row>
            <br />
            <br />
            <br />
            <br />
            <h3>
              <center>
                <span className="welcome">
                  Or check out some of the most popular...
                </span>
              </center>
            </h3>
            <Row className="search-form-container inherit-position">
              <MDBBtnGroup className="mr-2" size={isMobile ? "sm" : "lg"}>
                <MDBBtn
                  color="cyan lighten-2"
                  onClick={() => this.showPopular("popTV")}
                  className={
                    this.state.type === "popTV" ? "" : "opacity-button"
                  }
                >
                  TV Shows
                </MDBBtn>
                <MDBBtn
                  color="cyan lighten-2"
                  onClick={() => this.showPopular("popMovies")}
                  className={
                    this.state.type === "popMovies" ? "" : "opacity-button"
                  }
                >
                  Movies
                </MDBBtn>
                <MDBBtn
                  color="cyan lighten-2"
                  onClick={() => this.showPopular("popTrailers")}
                  className={
                    this.state.type === "popTrailers" ? "" : "opacity-button"
                  }
                >
                  Trailers
                </MDBBtn>
              </MDBBtnGroup>
            </Row>
            {this.state.show}
          </Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <FooterComponent />
        </div>
      </>
    );
  }
}

const HomeComponent = connect(mapStateToProps, mapDispatchToProps)(Home);

export default withRouter(HomeComponent);
