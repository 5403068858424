import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";

import Show from "./Show.js";

import { getUser, getFriends } from "../actions";
import firebase, { db } from "../base.js";

const mapStateToProps = state => {
  return { user: state.user, friends: state.friends };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: payload => dispatch(getUser(payload)),
    getFriends: payload => dispatch(getFriends(payload))
  };
};

class PopularShows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popular: null,
      isLoading: true
    };
  }

  componentDidMount() {
    if (firebase.auth().currentUser) {
      this.props
        .getUser(firebase.auth().currentUser.uid)
        .then(() => {
          this.props.getFriends(
            this.props.user ? this.props.user.groups[0] : ""
          );
        })
        .then(() =>
          this.getReviewed().then(() =>
            this.getPopular(
              this.props.type,
              this.props.length ? this.props.length : 100
            )
          )
        );
    } else {
      this.getPopular(
        this.props.type,
        this.props.length ? this.props.length : 100
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type) {
      this.setState({ isLoading: true, movieList: null });
      this.getPopular(
        this.props.type,
        this.props.length ? this.props.length : 100
      );
    }

    /*
    Object.entries(this.props).forEach(([key, val]) => {
      prevProps[key] !== val && console.log(`Prop '${key}' changed`);
    });
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) =>
          prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }*/
  }

  getPopular = async (search, length) => {
    const popular = await (await fetch(
      `${process.env.REACT_APP_FUNC_DOMAIN}/${search}`
    )
      .then(resp => resp.json()) // Transform the data into json
      .then(data => {
        this.setState({ isLoading: false });

        return data.slice(0, length);
      })
      .catch(err => {
        console.error("Error getting shows: " + err);
        console.log(`DOMAIN: ${process.env.REACT_APP_FUNC_DOMAIN}/${search}`);
      }));

    let type = "";
    switch (search) {
      case "popTV":
        type = "TV Shows";
        break;
      case "popMovies":
        type = "Movies";
        break;
      default:
        type = "Trailers";
        break;
    }

    const movieList = popular.map((item, i) => {
      const show = { ...item };
      show.i = i + "show";
      show.reviewed = false;

      if (firebase.auth().currentUser) {
        show.reviewed =
          this.state.imdbIDs && this.state.imdbIDs.includes(item.imdbID);
        show.friends = this.props.friends ? this.props.friends.length : 0;
      }

      return <Row key={i}>{Show(show)}</Row>;
    });

    this.setState({ popular, type, movieList });
  };

  getReviewed = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const { uid } = currentUser;
      this.props.getUser(uid).then(() => {
        db.collection("userMovieList")
          .where("userId", "==", uid)
          .where(
            "group",
            "==",
            this.props.user ? this.props.user.groups[0] : ""
          )
          .get()
          .then(querySnapshot => {
            const dataDB = querySnapshot.docs.map(doc => doc.data());
            const unique = [...new Set(dataDB.map(item => item.imdbID))];

            const userMovies = [];
            unique.forEach(id => {
              const find = dataDB.find(
                x => id === x.imdbID && uid === x.userId
              );
              if (find) userMovies.push(find);
            });
            const imdbIDs = userMovies.map(u => u.imdbID);

            this.setState({
              imdbIDs
            });
          })
          .catch(error => {
            console.error("Error getting documents: ", error);
          });
      });
    }
  };

  render() {
    return (
      <>
        <Container className="search-form-container-list">
          <br />
          <br />
          <Row className="center-card" id="first">
            {this.props.showHeader && (
              <>
                <h3 className="center extra-margin color-aqua">
                  The {this.state.movieList && this.state.movieList.length} Most
                  Popular {this.state.type}...
                </h3>
                <br />{" "}
              </>
            )}
            <br />
            <br />
            <PulseLoader
              sizeUnit={"px"}
              size={26}
              color={"red"}
              loading={this.state.isLoading}
            />
            {this.state.movieList}
            <br />
          </Row>
        </Container>
      </>
    );
  }
}

const PopularShowsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PopularShows);

export default withRouter(PopularShowsComponent);
