const SERVICES = new Map([
  [1, "Amazon Prime"],
  [2, "Apple TV+"],
  [3, "Cable TV"],
  [4, "CBS All Access"],
  [5, "Disney+"],
  [6, "HBO"],
  [7, "Hulu"],
  [8, "iTunes"],
  [9, "Movie Theater"],
  [10, "Netflix"],
  [11, "Showtime"],
  [12, "YouTube Originals"],
  [13, "Plex"],
  [14, "AMC"]
]);

const isProd = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging";
const PROD_HP_URL = isProd
  ? "https://www.thegoodflix.com"
  : "http://localhost:3000";
const PROD_APP_URL = isProd
  ? process.env.REACT_APP_DOMAIN
  : "http://localhost:3000";
const SUPPORT_EMAIL = "support@thegoodflix.com";

export { SERVICES, isProd, PROD_HP_URL, PROD_APP_URL, SUPPORT_EMAIL };
