import Swal from "sweetalert2";
import qs from "qs";
import firebase, { db, analytics } from "../base.js";

// import MailChecker from 'mailchecker';

import { SUPPORT_EMAIL, PROD_APP_URL, isProd } from "./variables.js";

const processGroup = async data => {
  const {
    checked,
    email,
    password,
    displayName,
    picture = null,
    group,
    oAuth
  } = data;

  const groupsDB = async (id, groupValid) => {
    // Set the group to taken
    if (checked) {
      db.collection("groups")
        .doc(id)
        .update({ taken: true });
    }

    const currentUser = firebase.auth().currentUser;

    await db
      .collection("users")
      .doc(currentUser.uid)
      .set({
        groups: firebase.firestore.FieldValue.arrayUnion(groupValid),
        picture,
        displayName
      });

    await db
      .collection("emails")
      .doc(currentUser.uid)
      .set({ email: currentUser.email });

    const friend_url = `mailto:?subject=Join me on theGoodflix&body=We can start sharing our shows on theGoodflix.%0D%0A%0D%0AHere is the signup: ${PROD_APP_URL}/signup?group=${encodeURIComponent(
      encodeURIComponent(groupValid)
    )}`;

    // Let Friends know you joined
    await db
      .collection("users")
      .where("groups", "array-contains", groupValid)
      .get()
      .then(async querySnapShot => {
        if (!querySnapShot.empty) {
          const uids = querySnapShot.docs
            .map(doc => doc.id)
            .filter(x => x !== currentUser.uid);

          await db.collection("mail").add({
            bccUids: uids,
            template: {
              name: "new_friend",
              data: {
                displayName,
                friend_url,
                domain: process.env.REACT_APP_DOMAIN
              }
            }
          });
        } else {
          console.error("Friends not found");
        }
      });

    // Send Welcome Email -----------
    const welcomeData = {
      toUids: [currentUser.uid],
      template: {
        name: "welcome",
        data: {
          displayName,
          group: groupValid,
          invite_url: `${PROD_APP_URL}/signup?group=${encodeURIComponent(
            groupValid
          )}`,
          friend_url,
          domain: process.env.REACT_APP_DOMAIN
        }
      }
    };

    if (isProd) {
      // in prod BCC
      welcomeData.bcc = SUPPORT_EMAIL;
    }

    await db.collection("mail").add(welcomeData);
    // ------------------------------
  };

  try {
    let groupValid;
    let noMoreGroups = false;

    /*if(!MailChecker.isValid(email.value)){ 
		Swal.fire(
		  "Awk-ward",
		  "It seem this isn't a valid email. Wanna try again?",
		  "error"
		);
		setLoading(false);
  
		return;
	  }*/

    if (checked) {
      const groupsRef = db.collection("groups");

      await groupsRef
        .where("taken", "==", false)
        .limit(1)
        .get()
        .then(snapShot => {
          const data = snapShot.docs.map(doc => doc.id);

          if (data.length > 0) {
            groupValid = data[0];
          } else {
            db.collection("mail").add({
              to: SUPPORT_EMAIL,
              message: {
                subject: "ALERT: We need more group names",
                text: `theGoodflix ran out of group names. We need more now! This is the user who needs a group: ${email}`
              }
            });

            // setLoading(false);
            Swal.fire(
              "Whoops",
              "We seem to be too popular and have run out of new friend groups. Please email us at support@thegoodflix.com to request a group.",
              "error"
            );

            console.error("No more groups");
            noMoreGroups = true;
          }
        });
    } else if (group && group !== "") {
      groupValid = group.trim().toLowerCase();
    } else {
      // setLoading(false);
      Swal.fire(
        "One More Thing",
        "You need to either enter a group name or check the box to create new one.",
        "error"
      );
    }

    if (!noMoreGroups) {
      const groups = await db.collection("groups").doc(groupValid);

      return await groups.get().then(async docSnapshot => {
        if (docSnapshot.exists) {
          try {
            if (oAuth) {
              await firebase.auth().currentUser.updateProfile({
                displayName: displayName
              });

              await groupsDB(docSnapshot.id, groupValid);
            } else {
              await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(() => {
                  firebase.auth().currentUser.updateProfile({
                    displayName: displayName
                  });
                })
                .then(async () => {
                  await groupsDB(docSnapshot.id, groupValid);
                });

              return true;
            }
          } catch (error) {
            console.error(error + " : " + JSON.stringify(email, null, 2));
            Swal.fire(
              "Oh-No!",
              `Eeekkk! There was an error! Here are the messy details: ${error}`,
              "error"
            );
          }
        } else {
          Swal.fire(
            "Oh-No!",
            `The group "${group}" wasn't found. Check the box below to create a new group to share with your friends.`,
            "error"
          );
        }
      });
    }
  } catch (error) {
    console.error(error + " : " + email);
    Swal.fire(
      "Arrr!",
      `There be an error! Did you enter a group name or check the box to auto-create a group?`,
      "error"
    );
  }
};

const processSocialAuth = (authResult, redirectUrl, history) => {
  let displayName, picture;

  try {
    if (authResult.additionalUserInfo.providerId === "google.com") {
      console.log("Google Login");
      displayName = authResult.additionalUserInfo.profile.given_name;
      picture = authResult.additionalUserInfo.profile.picture;
    } else if (authResult.additionalUserInfo.providerId === "facebook.com") {
      console.log("Facebook Login");
      displayName = authResult.additionalUserInfo.profile.first_name;
      picture = authResult.additionalUserInfo.profile.picture.data.url;
    } else {
      console.error("Provider not found.");
    }
  } catch (err) {
    console.log("error with auth: " + err);
  }

  const docRef = db.collection("users").doc(firebase.auth().currentUser.uid);

  docRef
    .get()
    .then(async docSnapShot => {
      if (!docSnapShot.exists) {
        console.log("Group doesn't exist");

        const { group } = qs.parse(window.location.search.slice(1));
        const { email } = authResult.additionalUserInfo.profile;
        const data = {
          checked: group ? false : true,
          group,
          displayName,
          email,
          picture,
          oAuth: true
        };

        analytics.logEvent("sign_up", {
          method: authResult.additionalUserInfo.providerId,
          email
        });

        await processGroup(data);
      } else {
        console.log("Group exists");
        analytics.logEvent("login", {
          method: authResult.additionalUserInfo.providerId
        });
        docRef.update({
          picture
        });
      }
    })
    .then(() => {
      const params = window.location.search.slice(1);
      move(history, `/${params ? `?${params}` : ""}`);
    });
};

const move = (history, redirectUrl) => {
  console.log("moving: " + redirectUrl);
  history.push(redirectUrl);
};

export { processGroup, processSocialAuth };
