import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// import "firebase/storage";

import "firebase/analytics";
import "firebase/performance";
// import rt from "./assets/img/rt/rt.png";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
const performance = firebase.performance();
const db = firebase.firestore();
const analytics = firebase.analytics();

/*const storageRef = firebase.storage().ref();
const mountainsRef = storageRef.child('happytime.jpg'); 
mountainsRef.putString(rt).then(function(snapshot) {
  console.log('Uploaded a blob or file!');
}).catch(console.error); */


/*
const storageRef = firebase.storage().ref();
storageRef.child('story-master.zip').getDownloadURL().then(url => {
	return console.log('URL: ' + url);
}).catch(console.error);
*/


export default firebase;
export { db, firebaseConfig, analytics, performance };
