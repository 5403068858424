import React from "react";
import { Link, withRouter } from "react-router-dom";

import './assets/css/style-404.css'; 

class Error404 extends React.Component {
  render() {
    return (
      <div id="notfound">
        <div class="notfound">
          <div class="notfound-404">
            <h1>
              4<span></span>4
            </h1>
          </div>
          <h2>Oops! Page Not Be Found</h2>
          <p>
            Sorry but the page you are looking for does not exist, have been
            removed. name changed or is temporarily unavailable
          </p>
		  <br/><br/>
          <Link to="/">Back to the source</Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Error404);
