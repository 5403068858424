import React, { useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { MDBBtn, MDBView, MDBMask, MDBCard } from "mdbreact";
import { Col } from "reactstrap";
import Swal from "sweetalert2";

import firebase from "../base";

import SubmitReviewComponent from "./SubmitReviewComponent.jsx";
import EyeComponent from "./EyeComponent.jsx";

const Show = data => {
  let history = useHistory();
  const [reviewed, setReviewed] = useState(false);
  const [show, setShow] = useState(false);

  const image = () => {
    if (!data.Poster || data.Poster !== "N/A") {
      return <img src={data.Poster} className="img-fluid" alt="" />;
    } else {
      return;
    }
  };

  const reviewedToggle = () => {
    setReviewed(true);
  };

  const handleClose = () => {
    if (firebase.auth().currentUser) {
      setShow(!show);
    } else {
      Swal.fire({
        title: "Sign Up to Give a Review",
        text: "Sign Up for Free to start sharing reviews with friends.",
        icon: "success",
        cancelButtonText: "later",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Let's Do It!"
      }).then(result => {
        if (result.value) {
          history.push("/signup");
        }
      });
    }
  };

  const openWindow = (videoLink, title) => {
    console.log("Launching new window: " + videoLink);

    window.open(
      `${videoLink}, ${title}, '_blank', 'resizable,height=260,width=370'`
    );

    return false;
  };

  return (
    <>
      <Col
        xs="12"
        sm="12"
        lg="12"
        className="card-margin"
        style={{ padding: "20px" }}
      >
        <MDBCard>
          <div className="view overlay hm-white-slight poster-img">
            {data.videoLink ? (
              <div
                onClick={() => openWindow(data.videoLink, data.Title)}
                className="pointer"
              >
                {image()}
                <img
                  alt={data.Title}
                  title={data.Plot}
                  className="image-overlay"
                  src="https://m.media-amazon.com/images/G/01/imdb/images/icons/play-button-3985929762._CB470041793_.png"
                ></img>
              </div>
            ) : (
              <MDBView hover>
                {image()}
                <MDBMask className="flex-center" overlay="stylish-strong">
                  <p className="white-text poster-text">{data.Plot}</p>
                </MDBMask>
              </MDBView>
            )}
          </div>

          <div className="card-block">
            <div className="poster-title poster-img">
              <h4 className="card-title">
                {data.Title}
                {!data.Episode && data.Type === "series" && (
                  <>
                    <br />
                    <span className="font-1 pointer color-blue font-weight-400">
                      <Link to={`/movies?i=${data.imdbID}`}>See Episodes</Link>
                    </span>
                  </>
                )}
              </h4>
              {data.Episode && (
                <>
                  <h5 className="card-title">
                    S{data.Season}E{data.Episode}
                  </h5>
                </>
              )}
            </div>
            <div className="poster-text">
              <p className="card-text">
                Watchlist:{" "}
                <span className="font-1-3">
                  <EyeComponent
                    imdbID={data.imdbID}
                    title={
                      data.seriesTitle
                        ? `${data.seriesTitle}: ${data.Title}`
                        : data.Title
                    }
                    poster={data.Poster}
                    type={data.Type}
                    season={data.Season}
                    episode={data.Episode}
                    i={data.i}
                  />
                </span>
                <br />
                Released: {data.Year}
              </p>
            </div>
            <div className="read-more font-1-2">
              {data.reviewed || reviewed ? (
                <Link to="/uml" alt="User List">
                  <MDBBtn color=" mdb-color lighten-2" className="font-1">
                    <span className="white-color">See your review</span>
                  </MDBBtn>
                </Link>
              ) : (
                <MDBBtn
                  color=" cyan lighten-1"
                  className="font-1"
                  onClick={() => handleClose()}
                >
                  <span className="white-color">Give your review</span>
                </MDBBtn>
              )}
            </div>
            <div className="read-more">
              <Link to={`/detail/${data.imdbID}`}>read more</Link>
            </div>
          </div>
        </MDBCard>
        <SubmitReviewComponent
          data={data}
          show={show}
          handleClose={() => handleClose()}
          callback={() => reviewedToggle()}
        />
      </Col>
    </>
  );
};

export default withRouter(Show);
