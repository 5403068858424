import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput
} from "mdbreact";
import Swal from "sweetalert2";
import PulseLoader from "react-spinners/PulseLoader";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import firebase, { analytics } from "./base.js";
import { processSocialAuth } from "./utils/auth.js";
import { PROD_HP_URL } from "./utils/variables.js";
import logo from "./assets/img/TGF-logo-white.png";

const Login = ({ history }) => {
  const [loading, setLoading] = useState(false);

  // Configure FirebaseUI.
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        setLoading(true);
        processSocialAuth(authResult, redirectUrl, history);

        return false;
      }
    }
    // tosUrl: 'https://thegoodflix.com/terms-of-use/',
    // privacyPolicyUrl: 'https://thegoodflix.com/privacy-policy/'
  };

  const handleLogin = async event => {
    event.preventDefault();

    setLoading(true);

    const { email, password } = event.target.elements;
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then(() => {
          analytics.logEvent("login", { method: 'email' });
          const params = window.location.search.slice(1);
          history.push(`/${params ? `?${params}` : ''}`);
        });
    } catch (error) {
      console.error(error + " : " + email.value);
      Swal.fire(
        "Arrr!",
        `There was a problem logging you in! Please check your email/password and try again. Or head over the the Sign Up page if you don't have an account.`,
        "error"
      );
      setLoading(false);
    }
  };

  const sendPassword = () => {
    Swal.fire({
      title: "Enter Email to Reset Password",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: false,
      confirmButtonText: "Reset Password",
      showLoaderOnConfirm: true,
      preConfirm: email => {
        firebase.auth().sendPasswordResetEmail(email);
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.value) {
        Swal.fire({
          title: "Email Has Been Sent"
        });
      }
    });
  };

  window.scroll(0, 0);

  return (
    <MDBContainer style={{ margin: "0 auto" }}>
      <MDBRow>
        <MDBCol md="6" className="ml-auto mr-auto">
          <MDBCard>
            <div className="header pt-3 red">
              <MDBRow className="d-flex justify-content-start">
                <h3 className="white-text mt-3 mb-4 pb-1 mx-5">
                  <a href={PROD_HP_URL}>
                    <img
                      src={logo}
                      alt="TheGoodflix Logo"
                      className="logo-login"
                    />
                  </a>
                </h3>
              </MDBRow>
            </div>
            <br />
            <h3 className="grey-text mt-3 mb-4 pb-1 mx-5">
              <span className="center">Log in with</span>
            </h3>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
            <Form onSubmit={handleLogin}>
              <br />
              <h3 className="grey-text mt-3 mb-4 pb-1 mx-5">
                <span className="center">or</span>
              </h3>
              <MDBCardBody className="mx-4 mt-4">
                <MDBInput
                  label="Your email..."
                  name="email"
                  group
                  type="text"
                  autoComplete="username"
                  validate
                  required
                />
                <MDBInput
                  label="Your password..."
                  group
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  validate
                  containerClass="mb-0"
                  required
                />
                <p className="font-small grey-text d-flex justify-content-end">
                  Forgot
                  <span
                    onClick={sendPassword}
                    className="dark-grey-text font-weight-bold ml-1 pointer"
                  >
                    Password?
                  </span>
                </p>
                <div className="text-center mb-4 mt-5">
                  {loading ? (
                    <PulseLoader
                      //css={override}
                      sizeUnit={"px"}
                      size={26}
                      color={"red"}
                      loading={loading}
                    />
                  ) : (
                    <MDBBtn
                      type="submit"
                      className="btn-block z-depth-2"
                      color="info"
                    >
                      Log in
                    </MDBBtn>
                  )}
                </div>
                <p className="font-small grey-text d-flex justify-content-center">
                  Don't have an account?
                  <Link
                    to="/signup"
                    className="dark-grey-text font-weight-bold ml-1"
                  >
                    Sign up
                  </Link>
                </p>
              </MDBCardBody>
            </Form>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default withRouter(Login);
