import {
  START_FETCHING,
  SET_PAGE,
  SET_MOVIE_LIST,
  SET_MOVIE_DETAIL,
  SET_USER,
  SET_WATCHLIST,
  SET_FRIENDS
} from "./constants";

const initialState = {
  items: { Search: [], totalResults: 0 },
  page: 1,
  item: {},
  isLoading: false,
  q: "",
  user: null,
  watchlist: new Set()
};

function rootReducer(state = initialState, action) {
  if (action.type === SET_MOVIE_LIST) {
    return {
      ...state,
      items: action.payload,
      isLoading: false
    };
  }

  if (action.type === SET_MOVIE_DETAIL) {
    return {
      ...state,
      item: action.payload,
      isLoading: false
    };
  }

  if (action.type === START_FETCHING) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === SET_PAGE) {
    return {
      ...state,
      page: action.payload
    };
  }

  if (action.type === SET_USER) {
    return {
      ...state,
      user: action.payload,
      isLoading: false
    };
  }

  if (action.type === SET_WATCHLIST) {
    return {
      ...state,
      watchlist: action.payload,
      isLoading: false
    };
  }

  if (action.type === SET_FRIENDS) {
    return {
      ...state,
      friends: action.payload,
      isLoading: false
    };
  }

  return state;
}
export default rootReducer;
