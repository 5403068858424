import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import Home from "./Home";
import Login from "./Login";
import SignUp from "./SignUp";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";

import HomeComponent from "./components/HomeComponent.jsx";
import MovieListComponent from "./components/MovieListComponent.jsx";
import MovieDetailComponent from "./components/MovieDetailComponent.jsx";
import UserMovieListComponent from "./components/UserMovieListComponent.jsx";
import GroupMovieListComponent from "./components/GroupMovieListComponent.jsx";
import PopularShowsComponent from "./components/PopularShowsComponent.jsx";
import WatchListComponent from "./components/WatchListComponent.jsx";
import Error404 from "./Error404.jsx";

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <Switch>
              <PrivateRoute exact path="/" component={HomeComponent} />
              <PrivateRoute path="/home" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <PrivateRoute path="/search" component={HomeComponent} />
              <PrivateRoute path="/movies" component={MovieListComponent} />
              <Route path="/detail/:imdbID" component={MovieDetailComponent} />
              <Route path="/popular" component={PopularShowsComponent} />
              <PrivateRoute path="/uml" component={UserMovieListComponent} />
              <PrivateRoute path="/gml" component={GroupMovieListComponent} />
              <PrivateRoute path="/watchlist" component={WatchListComponent} />
              <Route component={Error404} />
            </Switch>
          </Router>
        </Provider>
      </AuthProvider>
    );
  }
}

export default App;
